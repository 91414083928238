@import "../theme/mixins";

$brand: 'ottosburger';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: H-Font;
  src: url("../assets/fonts/FrontageCondensed.otf");
}
@font-face {
  font-family: Fjalla-Font;
  src: url("../assets/fonts/FjallaOne-Regular.ttf");
}
:root {
  --brand: $brand;
  --ion-color-primary: #333333;
  --ion-color-primary-rgb: 51, 51, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2d2d2d;
  --ion-color-primary-tint: #474747;

  --ion-color-secondary: #72C096;
  --ion-color-secondary-rgb: 114, 192, 150;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #64a984;
  --ion-color-secondary-tint: #80c6a1;

  --ion-color-tertiary: #354C7A;
  --ion-color-tertiary-rgb: 53, 76, 122;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2f436b;
  --ion-color-tertiary-tint: #495e87;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #474646;
  --ion-color-dark-rgb: 71,70,70;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #3e3e3e;
  --ion-color-dark-tint: #595959;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #696766;
  --ion-color-light-rgb: 105,103,102;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #5c5b5a;
  --ion-color-light-tint: #787675;

  --ion-font-family: AppFont, Helvetica, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
}
